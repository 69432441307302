@use "sass:math";
@use "../global" as *;

.c-simulation {
  th {
    font-size: 36px;
    font-weight: 500;
    height: 164px;
    color: $white;
    padding: 20px 25px;
    line-height: 1.4;
    border-bottom: 2px solid $white;
    vertical-align: middle;
    width: 475px;
    @include mq(tb) {
      font-size: 14px;
      width: 120px;
      height: 80px;
      padding: 15px 10px;
    }
  }
  td {
    vertical-align: middle;
  }
  &__table {
    width: 100%;
    font-weight: 500;
    margin-bottom: 20px;
    tr {
      &:last-child {
        th {
          border-color: #79dad6;
        }
      }
    }
    th {
      background-color: #79dad6;
      span {
        display: block;
        font-size: 18px;
        @include mq(tb) {
          font-size: 12px;
        }
      }
    }
    td {
      padding: 2px 0 2px 10px;
      border-bottom: 2px solid #79dad6;
      span {
        display: block;
        font-size: 18px;
        @include mq(tb) {
          font-size: 12px;
        }
      }
      input[type="number"] {
        border-color: #79dad6;
        font-size: 72px;
        line-height: 1.5;
        padding: 0 20px;
        margin-bottom: 5px;
        @include mq(tb) {
          font-size: 18px;
          padding: 0 5px;
          max-width: 100px;
        }
      }
      input[type="radio"] {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
        &:checked {
          + label {
            &::after {
              opacity: 1;
            }
          }
        }
        &:disabled {
          + label {
            opacity: 0.4;
            cursor: default;
          }
        }
      }
      div {
        display: flex;
        flex-wrap: wrap;
      }
      label {
        min-width: 50%;
        padding-left: 60px;
        font-size: 36px;
        font-weight: 500;
        box-sizing: border-box;
        position: relative;
        @include mq(tb) {
          padding-left: 30px;
          font-size: 16px;
        }
        &::before,
        &::after {
          content: "";
          position: absolute;
          border-radius: 50%;
          top: 50%;
          transform: translateY(-50%);
        }
        &::before {
          width: 28px;
          height: 28px;
          border: 1px solid #dedede;
          left: 5px;
          @include mq(tb) {
            width: 18px;
            height: 18px;
          }
        }
        &::after {
          width: 18px;
          height: 18px;
          background-color: #010101;
          left: 10px;
          opacity: 0;
          transition: all 0.3s;
          @include mq(tb) {
            width: 12px;
            height: 12px;
            left: 8px;
          }
        }
      }
    }
  }
  &__result {
    margin-bottom: 30px;
    th {
      background-color: $green;
    }
    td {
      padding-left: 10px;
      @include mq(tb) {
        padding-left: 5px;
      }
      input {
        border-color: $green;
        font-size: 72px;
        line-height: 2;
        @include mq(tb) {
          font-size: 18px;
          padding: 0 5px;
        }
      }
    }
  }
}
