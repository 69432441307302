@use "sass:math";
@use "../global" as *;

.p-kiyaku {
  .u-bg-white {
    padding: 30px;
    @include mq {
      padding: 10px;
    }
  }
  dl {
    dt {
      font-size: 28px;
      @include mq {
        font-size: 18px;
      }
    }
    dd {
      margin-bottom: 1em;
      ol {
        counter-reset: number 0;
        li {
          position: relative;
          padding-left: 1.5em;
          &::before {
            counter-increment: number 1;
            content: counter(number) ".";
            position: absolute;
            left: 0;
            top: 0;
          }
          &:not(:last-child) {
            margin-bottom: 1.5em;
          }
        }
      }
      a {
        text-decoration: underline;
      }
    }
  }
}
