@use "sass:math";
@use "../global" as *;

.l-footer {
  background-color: $black;
  color: $white;
  padding: 60px 0 25px;
  @include mq {
    padding: 40px 0 70px;
  }
  &__nav {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    @include mq {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      font-size: 14px;
    }
  }
  &__copy {
    margin-top: 70px;
    small {
      font-size: 16px;
      @include mq {
        font-size: 10px;
      }
    }
  }
}
