@use "sass:math";
@use "../global" as *;

.c-form {
  input[type="radio"],
  input[type="checkbox"] {
    opacity: 0;
    width: 0;
    + span {
      position: relative;
    }
  }
  label {
    cursor: pointer;
  }
  input[type="radio"] {
    + span {
      padding-left: 1.6em;
      &::before {
        content: "";
        width: 28px;
        height: 28px;
        border: 1px solid #dedede;
        position: absolute;
        left: 0;
        top: 4px;
        border-radius: 50%;
        @include mq {
          width: 18px;
          height: 18px;
          top: 2px;
        }
      }
      &::after {
        content: "";
        width: 18px;
        height: 18px;
        background-color: $black;
        position: absolute;
        border-radius: 50%;
        left: 5px;
        top: 9px;
        opacity: 0;
        transition: opacity 0.3s;
        @include mq {
          width: 12px;
          height: 12px;
          top: 5px;
          left: 3px;
        }
      }
    }
    &:checked {
      + span {
        &::after {
          opacity: 1;
        }
      }
    }
  }
  input[type="checkbox"] {
    + span {
      padding-left: 1.4em;
      &::before {
        content: "";
        width: 22px;
        height: 22px;
        border: 1px solid #dedede;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        @include mq {
          width: 16px;
          height: 16px;
        }
      }
      &::after {
        content: "";
        width: 12px;
        height: 8px;
        border-left: 3px solid $black;
        border-bottom: 3px solid $black;
        position: absolute;
        left: 5px;
        top: 50%;
        margin-top: -6px;
        transform: rotate(-45deg);
        opacity: 0;
        transition: opacity 0.3s;
        @include mq {
          width: 10px;
          height: 6px;
          left: 3px;
          margin-top: -4px;
        }
      }
    }
    &:checked {
      + span {
        &::after {
          opacity: 1;
        }
      }
    }
  }
  .notes {
    font-size: 18px;
    @include mq {
      font-size: 12px;
    }
    a {
      text-decoration: underline;
    }
  }
  input[type="text"],
  input[type="number"],
  input[type="email"],
  textarea,
  select {
    line-height: 1.2;
    border-width: 1px;
    padding: 0.4em 0.5em;
    &::placeholder {
      color: #dedede;
    }
  }
  select {
    &.is-empty {
      color: #dedede;
    }
  }
  &__type {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 50px;
    @include mq {
      margin-bottom: 30px;
    }
  }
  &__hdg {
    text-align: center;
    font-size: 35px;
    color: $blue;
    margin-bottom: 40px;
    @include mq {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
  &__tab {
    display: flex;
    gap: 40px;
    margin-bottom: 35px;
    @include mq {
      gap: 10px;
      margin-bottom: 20px;
    }
    &__item {
      opacity: 0.5;
      transition: opacity 0.3s;
      cursor: pointer;
      &.is-active {
        opacity: 1;
        cursor: default;
      }
    }
    &__cont {
      display: none;
      &.is-active {
        display: block;
      }
    }
  }
  &__cont {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #c4daf8;
    @include mq {
      gap: 10px;
      padding-bottom: 15px;
      margin-bottom: 15px;
    }
    &__hdg {
      flex-shrink: 0;
      width: 180px;
      display: flex;
      justify-content: flex-end;
      text-align: right;
      white-space: nowrap;
      font-weight: 500;
      line-height: 1.2;
      padding-top: 8px;
      @include mq {
        width: 100%;
        text-align: left;
        justify-content: flex-start;
      }
    }
    &__input {
      width: calc(100% - 200px);
      @include mq {
        width: 100%;
      }
      p.notes {
        margin-top: 1em;
        @include mq {
          margin-top: 0.5em;
        }
      }
      span.notes {
        margin-left: 1em;
        @include mq {
          display: block;
          margin-top: 0.5em;
          margin-left: 0;
        }
      }
      .p-postal-code {
        width: 162px;
        display: inline-block;
      }
    }
    &__flex {
      display: flex;
      flex-wrap: wrap;
      gap: 20px 50px;
      @include mq {
        gap: 20px;
      }
    }
  }
  &__terms {
    text-align: center;
    margin-bottom: 50px;
    p {
      margin-bottom: 1em;
    }
  }
  &__change-item {
    display: none;
  }
}
