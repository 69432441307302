@use "sass:math";
@use "../global" as *;

@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;500;800&display=swap");
html {
  font-size: 10px;
}

body {
  font-size: 24px;
  color: $txtColor;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 500;
  line-height: 1.8;
  @include mq(tb) {
    font-size: 16px;
  }
}

img {
  max-width: 100%;
  height: auto;
}

a {
  transition: all 0.3s;
  &:hover {
    opacity: 0.7;
  }
}

.subpage {
  padding: 100px 0 45px;
  @include mq {
    padding: 40px 0 60px;
  }
  .c-box {
    &.u-bg-orange {
      padding-bottom: 50px;
      @include mq {
        padding-bottom: 30px;
      }
    }
    .c-btn02 {
      margin-top: 50px;
      @include mq {
        margin-top: 30px;
      }
    }
  }
}

input[type="text"],
input[type="number"],
input[type="email"],
textarea,
select {
  display: block;
  width: 100%;
  outline: none;
  border: 6px solid;
  @include mq(tb) {
    border-width: 2px;
  }
}

.sp-only {
  display: none;
  @include mq {
    display: block;
  }
}

.pc-only {
  @include mq {
    display: none;
  }
}
