@use "sass:math";
@use "../global" as *;

.l-header {
  padding: 30px 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  background-color: $white;
  @include mq(tb) {
    padding: 12px 8px;
  }
  &__logo {
    max-width: 352px;
    @include mq(tb) {
      max-width: 200px;
    }
  }
  &__list {
    display: flex;
    &__item {
      width: 260px;
      @include mq(tb) {
        width: 140px;
        &:last-child {
          display: none;
        }
      }
    }
  }
}
