@use "sass:math";
@use "../global" as *;

.c-hdg01 {
  text-align: center;
  margin: 0 auto 40px;
  width: 90%;
  @include mq(tb) {
    margin-bottom: 20px;
  }
}
