@use "sass:math";
@use "../global" as *;

.c-inner {
  max-width: 1070px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  @include mq(tb) {
    max-width: 500px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
