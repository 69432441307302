@use "sass:math";
@use "../global" as *;

.p-thanks {
  .c-btn02 {
    margin-top: 30px;
    @include mq {
      margin-top: 20px;
    }
  }
}
