@use "sass:math";
@use "../global" as *;

.u-tac {
  text-align: center;
}

.u-orange {
  color: $orange;
}

.u-pink {
  color: $pink;
}

.u-bg-white {
  background-color: $white;
}

.u-bg-orange {
  background-color: $orange;
}

.u-bg-blue {
  background-color: $blue;
}

.u-bg-orangeicn {
  background: url(../img/top/bg01.jpg);
}

.u-medium {
  font-weight: 500;
}

.u-bold {
  font-weight: bold;
}
