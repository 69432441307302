@use "sass:math";
@use "../global" as *;

.p-tradelaw {
  .u-bg-white {
    padding: 6px;
    table {
      font-size: 28px;
      @include mq {
        font-size: 16px;
      }
      th,
      td {
        font-weight: 500;
        border: 2px solid $white;
        padding: 20px;
        vertical-align: middle;
        text-align: left;
        @include mq {
          padding: 10px;
        }
      }
      th {
        background-color: $d_yellow;
        width: 283px;
        @include mq {
          width: 100%;
          display: block;
          border-bottom: none;
          padding: 5px 10px;
        }
      }
      td {
        background-color: #fff9eb;
        @include mq {
          width: 100%;
          display: block;
          border-top: none;
        }
      }
    }
  }
}
