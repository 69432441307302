@use "sass:math";
@use "../global" as *;

.c-box {
  padding: 30px;
  border-radius: 10px;
  @include mq(tb) {
    padding: 10px 10px 20px;
    border-radius: 5px;
  }
}
