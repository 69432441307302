@use "sass:math";
@use "../global" as *;

.c-notes {
  border-bottom: 1px solid #dedede;
  font-weight: 400;
  &__hdg {
    border: 1px solid #dedede;
    border-bottom: none;
    background-color: $white;
    padding: 25px 70px;
    position: relative;
    font-weight: 400;
    @include mq {
      padding: 15px 30px 15px 15px;
    }
    cursor: pointer;
    &::before,
    &::after {
      content: "";
      width: 20px;
      height: 2px;
      background-color: $pink;
      position: absolute;
      right: 30px;
      top: 50%;
      margin-top: -1px;
      transition: all 0.5s;
      @include mq {
        width: 16px;
        right: 12px;
      }
    }
    &::before {
      transform: rotate(-90deg);
    }
    &::after {
      transform: rotate(-180deg);
    }
    &.is-active {
      &::before,
      &::after {
        background-color: #febeda;
        transform: rotate(0);
      }
    }
  }
  &__txt {
    border: 1px solid #dedede;
    border-bottom: none;
    border-top: none;
    padding: 25px 70px 35px;
    background-color: #ffe3ef;
    display: none;
    line-height: 1.2;
    @include mq {
      padding: 15px 10px 20px;
    }
    &:first-of-type {
      display: block;
    }
    ul {
      padding-left: 1em;
      li {
        padding-left: 1em;
        position: relative;
        line-height: 1.4;
        &::before {
          content: "・";
          position: absolute;
          left: 0;
          top: 0;
        }
      }
      &.list {
        padding-left: 0;
        li {
          margin-bottom: 1em;
        }
      }
    }
    dl {
      dt {
        font-weight: 500;
        margin-bottom: 1em;
      }
      dd {
        padding-bottom: 1em;
        p {
          margin-bottom: 1em;
        }
      }
    }
  }
}
