@use "sass:math";
@use "sass:map";
@use "variables" as v;

@function fz($size) {
  @return clamp(#{$size}px * 2 / 3, 100vw * #{$size} / #{v.$base}, #{$size}px * 1.5);
}

@function vw($px) {
  @return (math.div($px, v.$base) * 100vw);
}

@function fzSp($size) {
  @return clamp(#{$size}px * 2 / 3, 100vw * #{$size} / #{v.$baseSp}, #{$size}px * 1.5);
}

@function vwSp($px) {
  @return (math.div($px, v.$baseSp) * 100vw);
}

$breakpoints: (
  "xsm": 350,
  "topMv": 501,
  "sm": 801,
  "tb": 1040,
  "pc": 1240,
  "pcL": 1440,
);

@mixin mq($point: sm, $dir: down) {
  @media screen and (#{if($dir != down, "min", "max")}-width: #{if(type-of($point) != number, if($dir != down, map.get($breakpoints,$point), map.get($breakpoints,$point) - 1), if($dir != down, $point, $point - 1))}px) {
    @content;
  }
}
