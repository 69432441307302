@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;500;800&display=swap");
html {
  font-size: 10px;
}

body {
  font-size: 24px;
  color: #010101;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 500;
  line-height: 1.8;
}
@media screen and (max-width: 1039px) {
  body {
    font-size: 16px;
  }
}

img {
  max-width: 100%;
  height: auto;
}

a {
  transition: all 0.3s;
}
a:hover {
  opacity: 0.7;
}

.subpage {
  padding: 100px 0 45px;
}
@media screen and (max-width: 800px) {
  .subpage {
    padding: 40px 0 60px;
  }
}
.subpage .c-box.u-bg-orange {
  padding-bottom: 50px;
}
@media screen and (max-width: 800px) {
  .subpage .c-box.u-bg-orange {
    padding-bottom: 30px;
  }
}
.subpage .c-box .c-btn02 {
  margin-top: 50px;
}
@media screen and (max-width: 800px) {
  .subpage .c-box .c-btn02 {
    margin-top: 30px;
  }
}

input[type=text],
input[type=number],
input[type=email],
textarea,
select {
  display: block;
  width: 100%;
  outline: none;
  border: 6px solid;
}
@media screen and (max-width: 1039px) {
  input[type=text],
  input[type=number],
  input[type=email],
  textarea,
  select {
    border-width: 2px;
  }
}

.sp-only {
  display: none;
}
@media screen and (max-width: 800px) {
  .sp-only {
    display: block;
  }
}

@media screen and (max-width: 800px) {
  .pc-only {
    display: none;
  }
}

.l-header {
  padding: 30px 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  background-color: #fff;
}
@media screen and (max-width: 1039px) {
  .l-header {
    padding: 12px 8px;
  }
}
.l-header__logo {
  max-width: 352px;
}
@media screen and (max-width: 1039px) {
  .l-header__logo {
    max-width: 200px;
  }
}
.l-header__list {
  display: flex;
}
.l-header__list__item {
  width: 260px;
}
@media screen and (max-width: 1039px) {
  .l-header__list__item {
    width: 140px;
  }
  .l-header__list__item:last-child {
    display: none;
  }
}

.l-footer {
  background-color: #1a1a1a;
  color: #fff;
  padding: 60px 0 25px;
}
@media screen and (max-width: 800px) {
  .l-footer {
    padding: 40px 0 70px;
  }
}
.l-footer__nav {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
}
@media screen and (max-width: 800px) {
  .l-footer__nav {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-size: 14px;
  }
}
.l-footer__copy {
  margin-top: 70px;
}
.l-footer__copy small {
  font-size: 16px;
}
@media screen and (max-width: 800px) {
  .l-footer__copy small {
    font-size: 10px;
  }
}

.c-hdg01 {
  text-align: center;
  margin: 0 auto 40px;
  width: 90%;
}
@media screen and (max-width: 1039px) {
  .c-hdg01 {
    margin-bottom: 20px;
  }
}

.c-btn01 {
  display: block;
  max-width: 302px;
  padding: 5px 15px;
  text-align: center;
  color: #fff;
}
@media screen and (max-width: 1039px) {
  .c-btn01 {
    max-width: 200px;
  }
}
.c-btn01__icn {
  display: block;
}
@media screen and (max-width: 1039px) {
  .c-btn01__icn {
    width: 20px;
    margin: 0 auto;
  }
}
.c-btn01__txt {
  font-size: 16px;
  display: block;
}
@media screen and (max-width: 1039px) {
  .c-btn01__txt {
    font-size: 10px;
  }
}
.c-btn01--green {
  background-color: #46cc64;
}
.c-btn01--blue {
  background-color: #3f88e9;
}
.c-btn01--red {
  background-color: #d00e31;
}

.c-btn02 {
  display: block;
  font-weight: 800;
  background-color: #fff;
  color: #fc804c;
  max-width: 320px;
  height: 50px;
  line-height: 50px;
  border-radius: 10px;
  margin: 0 auto;
  text-align: center;
}
@media screen and (max-width: 800px) {
  .c-btn02 {
    border-radius: 5px;
    max-width: 200px;
  }
}

.c-btn-cv {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
  width: 100%;
  max-width: 720px;
  border-radius: 16px;
  border: 2px solid #fff;
  background-color: #fc804c;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  font-size: 44px;
  color: #fff;
  font-weight: 800;
  line-height: 1;
  transition: opacity 0.3s;
}
@media screen and (max-width: 800px) {
  .c-btn-cv {
    border-radius: 10px;
    width: 90%;
    max-width: 300px;
    font-size: 16px;
  }
}
.c-btn-cv:hover {
  opacity: 0.7;
}
.c-btn-cv.btn-send img {
  width: 48px;
}
@media screen and (max-width: 800px) {
  .c-btn-cv.btn-send img {
    width: 24px;
  }
}

.c-inner {
  max-width: 1070px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 1039px) {
  .c-inner {
    max-width: 500px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.c-box {
  padding: 30px;
  border-radius: 10px;
}
@media screen and (max-width: 1039px) {
  .c-box {
    padding: 10px 10px 20px;
    border-radius: 5px;
  }
}

.c-simulation th {
  font-size: 36px;
  font-weight: 500;
  height: 164px;
  color: #fff;
  padding: 20px 25px;
  line-height: 1.4;
  border-bottom: 2px solid #fff;
  vertical-align: middle;
  width: 475px;
}
@media screen and (max-width: 1039px) {
  .c-simulation th {
    font-size: 14px;
    width: 120px;
    height: 80px;
    padding: 15px 10px;
  }
}
.c-simulation td {
  vertical-align: middle;
}
.c-simulation__table {
  width: 100%;
  font-weight: 500;
  margin-bottom: 20px;
}
.c-simulation__table tr:last-child th {
  border-color: #79dad6;
}
.c-simulation__table th {
  background-color: #79dad6;
}
.c-simulation__table th span {
  display: block;
  font-size: 18px;
}
@media screen and (max-width: 1039px) {
  .c-simulation__table th span {
    font-size: 12px;
  }
}
.c-simulation__table td {
  padding: 2px 0 2px 10px;
  border-bottom: 2px solid #79dad6;
}
.c-simulation__table td span {
  display: block;
  font-size: 18px;
}
@media screen and (max-width: 1039px) {
  .c-simulation__table td span {
    font-size: 12px;
  }
}
.c-simulation__table td input[type=number] {
  border-color: #79dad6;
  font-size: 72px;
  line-height: 1.5;
  padding: 0 20px;
  margin-bottom: 5px;
}
@media screen and (max-width: 1039px) {
  .c-simulation__table td input[type=number] {
    font-size: 18px;
    padding: 0 5px;
    max-width: 100px;
  }
}
.c-simulation__table td input[type=radio] {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.c-simulation__table td input[type=radio]:checked + label::after {
  opacity: 1;
}
.c-simulation__table td input[type=radio]:disabled + label {
  opacity: 0.4;
  cursor: default;
}
.c-simulation__table td div {
  display: flex;
  flex-wrap: wrap;
}
.c-simulation__table td label {
  min-width: 50%;
  padding-left: 60px;
  font-size: 36px;
  font-weight: 500;
  box-sizing: border-box;
  position: relative;
}
@media screen and (max-width: 1039px) {
  .c-simulation__table td label {
    padding-left: 30px;
    font-size: 16px;
  }
}
.c-simulation__table td label::before, .c-simulation__table td label::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}
.c-simulation__table td label::before {
  width: 28px;
  height: 28px;
  border: 1px solid #dedede;
  left: 5px;
}
@media screen and (max-width: 1039px) {
  .c-simulation__table td label::before {
    width: 18px;
    height: 18px;
  }
}
.c-simulation__table td label::after {
  width: 18px;
  height: 18px;
  background-color: #010101;
  left: 10px;
  opacity: 0;
  transition: all 0.3s;
}
@media screen and (max-width: 1039px) {
  .c-simulation__table td label::after {
    width: 12px;
    height: 12px;
    left: 8px;
  }
}
.c-simulation__result {
  margin-bottom: 30px;
}
.c-simulation__result th {
  background-color: #00b9b1;
}
.c-simulation__result td {
  padding-left: 10px;
}
@media screen and (max-width: 1039px) {
  .c-simulation__result td {
    padding-left: 5px;
  }
}
.c-simulation__result td input {
  border-color: #00b9b1;
  font-size: 72px;
  line-height: 2;
}
@media screen and (max-width: 1039px) {
  .c-simulation__result td input {
    font-size: 18px;
    padding: 0 5px;
  }
}

.c-list-needs {
  display: grid;
  gap: 30px 50px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 40px;
}
@media screen and (max-width: 800px) {
  .c-list-needs {
    grid-template-columns: 1fr;
    gap: 20px;
    margin-bottom: 30px;
  }
}
.c-list-needs__item {
  display: flex;
  align-items: center;
  gap: 20px;
}
@media screen and (max-width: 800px) {
  .c-list-needs__item {
    gap: 15px;
    line-height: 1.4;
  }
}
.c-list-needs__item .img {
  width: 118px;
}
@media screen and (max-width: 800px) {
  .c-list-needs__item .img {
    width: 60px;
  }
}

.c-list-fixed {
  position: fixed;
  z-index: 9999;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  display: none;
}
@media screen and (max-width: 800px) {
  .c-list-fixed {
    display: flex;
  }
}
.c-list-fixed__item {
  width: 50%;
  line-height: 1.2;
}
.c-list-fixed__item .c-btn01 {
  max-width: 100%;
  padding: 5px 10px 3px;
}
.c-list-fixed__item .c-btn01__icn {
  width: 16px;
  margin-bottom: 3px;
}

.c-list-flow {
  position: relative;
  background-color: #fff;
  padding: 50px 40px 40px;
}
@media screen and (max-width: 800px) {
  .c-list-flow {
    padding: 30px 15px 15px;
  }
}
.c-list-flow:not(:last-of-type) {
  margin-bottom: 65px;
}
@media screen and (max-width: 800px) {
  .c-list-flow:not(:last-of-type) {
    margin-bottom: 40px;
  }
}
.c-list-flow dt {
  background-color: #0eccc3;
  width: 90%;
  max-width: 485px;
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 35px;
  border-radius: 10px;
  border: 2px solid #fff;
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 800px) {
  .c-list-flow dt {
    max-width: 300px;
    font-size: 18px;
  }
}
.c-list-flow dt::before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 10px 0 10px;
  border-color: #0eccc3 transparent transparent transparent;
  position: absolute;
  left: 50%;
  bottom: -18px;
  margin-left: -10px;
}
@media screen and (max-width: 800px) {
  .c-list-flow dt::before {
    border-width: 10px 5px 0 5px;
    bottom: -9px;
    margin-left: -5px;
  }
}
.c-list-flow dd {
  display: flex;
  gap: 40px;
  line-height: 1.2;
}
@media screen and (max-width: 800px) {
  .c-list-flow dd {
    gap: 10px;
  }
}
.c-list-flow dd .icn {
  width: 80px;
  text-align: center;
  flex-shrink: 0;
}
@media screen and (max-width: 800px) {
  .c-list-flow dd .icn {
    width: 40px;
  }
}
.c-list-flow dd .txt {
  margin-top: 0.5em;
}
.c-list-flow dd .txt p:not(:first-of-type) {
  margin-top: 1.5em;
}
@media screen and (max-width: 800px) {
  .c-list-flow dd .txt p:not(:first-of-type) {
    margin-top: 0;
  }
}
.c-list-flow dd .txt a {
  color: #3f88e9;
}

.c-cv {
  padding: 45px 0;
  background-color: #fee300;
}
@media screen and (max-width: 800px) {
  .c-cv {
    padding: 30px 0;
  }
}
.c-cv h2 {
  margin-bottom: 10px;
}
@media screen and (max-width: 800px) {
  .c-cv h2 {
    width: 80%;
    margin: 0 auto 10px;
  }
}

.c-form input[type=radio],
.c-form input[type=checkbox] {
  opacity: 0;
  width: 0;
}
.c-form input[type=radio] + span,
.c-form input[type=checkbox] + span {
  position: relative;
}
.c-form label {
  cursor: pointer;
}
.c-form input[type=radio] + span {
  padding-left: 1.6em;
}
.c-form input[type=radio] + span::before {
  content: "";
  width: 28px;
  height: 28px;
  border: 1px solid #dedede;
  position: absolute;
  left: 0;
  top: 4px;
  border-radius: 50%;
}
@media screen and (max-width: 800px) {
  .c-form input[type=radio] + span::before {
    width: 18px;
    height: 18px;
    top: 2px;
  }
}
.c-form input[type=radio] + span::after {
  content: "";
  width: 18px;
  height: 18px;
  background-color: #1a1a1a;
  position: absolute;
  border-radius: 50%;
  left: 5px;
  top: 9px;
  opacity: 0;
  transition: opacity 0.3s;
}
@media screen and (max-width: 800px) {
  .c-form input[type=radio] + span::after {
    width: 12px;
    height: 12px;
    top: 5px;
    left: 3px;
  }
}
.c-form input[type=radio]:checked + span::after {
  opacity: 1;
}
.c-form input[type=checkbox] + span {
  padding-left: 1.4em;
}
.c-form input[type=checkbox] + span::before {
  content: "";
  width: 22px;
  height: 22px;
  border: 1px solid #dedede;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 800px) {
  .c-form input[type=checkbox] + span::before {
    width: 16px;
    height: 16px;
  }
}
.c-form input[type=checkbox] + span::after {
  content: "";
  width: 12px;
  height: 8px;
  border-left: 3px solid #1a1a1a;
  border-bottom: 3px solid #1a1a1a;
  position: absolute;
  left: 5px;
  top: 50%;
  margin-top: -6px;
  transform: rotate(-45deg);
  opacity: 0;
  transition: opacity 0.3s;
}
@media screen and (max-width: 800px) {
  .c-form input[type=checkbox] + span::after {
    width: 10px;
    height: 6px;
    left: 3px;
    margin-top: -4px;
  }
}
.c-form input[type=checkbox]:checked + span::after {
  opacity: 1;
}
.c-form .notes {
  font-size: 18px;
}
@media screen and (max-width: 800px) {
  .c-form .notes {
    font-size: 12px;
  }
}
.c-form .notes a {
  text-decoration: underline;
}
.c-form input[type=text],
.c-form input[type=number],
.c-form input[type=email],
.c-form textarea,
.c-form select {
  line-height: 1.2;
  border-width: 1px;
  padding: 0.4em 0.5em;
}
.c-form input[type=text]::placeholder,
.c-form input[type=number]::placeholder,
.c-form input[type=email]::placeholder,
.c-form textarea::placeholder,
.c-form select::placeholder {
  color: #dedede;
}
.c-form select.is-empty {
  color: #dedede;
}
.c-form__type {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 50px;
}
@media screen and (max-width: 800px) {
  .c-form__type {
    margin-bottom: 30px;
  }
}
.c-form__hdg {
  text-align: center;
  font-size: 35px;
  color: #3f88e9;
  margin-bottom: 40px;
}
@media screen and (max-width: 800px) {
  .c-form__hdg {
    font-size: 20px;
    margin-bottom: 20px;
  }
}
.c-form__tab {
  display: flex;
  gap: 40px;
  margin-bottom: 35px;
}
@media screen and (max-width: 800px) {
  .c-form__tab {
    gap: 10px;
    margin-bottom: 20px;
  }
}
.c-form__tab__item {
  opacity: 0.5;
  transition: opacity 0.3s;
  cursor: pointer;
}
.c-form__tab__item.is-active {
  opacity: 1;
  cursor: default;
}
.c-form__tab__cont {
  display: none;
}
.c-form__tab__cont.is-active {
  display: block;
}
.c-form__cont {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #c4daf8;
}
@media screen and (max-width: 800px) {
  .c-form__cont {
    gap: 10px;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
}
.c-form__cont__hdg {
  flex-shrink: 0;
  width: 180px;
  display: flex;
  justify-content: flex-end;
  text-align: right;
  white-space: nowrap;
  font-weight: 500;
  line-height: 1.2;
  padding-top: 8px;
}
@media screen and (max-width: 800px) {
  .c-form__cont__hdg {
    width: 100%;
    text-align: left;
    justify-content: flex-start;
  }
}
.c-form__cont__input {
  width: calc(100% - 200px);
}
@media screen and (max-width: 800px) {
  .c-form__cont__input {
    width: 100%;
  }
}
.c-form__cont__input p.notes {
  margin-top: 1em;
}
@media screen and (max-width: 800px) {
  .c-form__cont__input p.notes {
    margin-top: 0.5em;
  }
}
.c-form__cont__input span.notes {
  margin-left: 1em;
}
@media screen and (max-width: 800px) {
  .c-form__cont__input span.notes {
    display: block;
    margin-top: 0.5em;
    margin-left: 0;
  }
}
.c-form__cont__input .p-postal-code {
  width: 162px;
  display: inline-block;
}
.c-form__cont__flex {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 50px;
}
@media screen and (max-width: 800px) {
  .c-form__cont__flex {
    gap: 20px;
  }
}
.c-form__terms {
  text-align: center;
  margin-bottom: 50px;
}
.c-form__terms p {
  margin-bottom: 1em;
}
.c-form__change-item {
  display: none;
}

.c-notes {
  border-bottom: 1px solid #dedede;
  font-weight: 400;
}
.c-notes__hdg {
  border: 1px solid #dedede;
  border-bottom: none;
  background-color: #fff;
  padding: 25px 70px;
  position: relative;
  font-weight: 400;
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  .c-notes__hdg {
    padding: 15px 30px 15px 15px;
  }
}
.c-notes__hdg::before, .c-notes__hdg::after {
  content: "";
  width: 20px;
  height: 2px;
  background-color: #fe75b0;
  position: absolute;
  right: 30px;
  top: 50%;
  margin-top: -1px;
  transition: all 0.5s;
}
@media screen and (max-width: 800px) {
  .c-notes__hdg::before, .c-notes__hdg::after {
    width: 16px;
    right: 12px;
  }
}
.c-notes__hdg::before {
  transform: rotate(-90deg);
}
.c-notes__hdg::after {
  transform: rotate(-180deg);
}
.c-notes__hdg.is-active::before, .c-notes__hdg.is-active::after {
  background-color: #febeda;
  transform: rotate(0);
}
.c-notes__txt {
  border: 1px solid #dedede;
  border-bottom: none;
  border-top: none;
  padding: 25px 70px 35px;
  background-color: #ffe3ef;
  display: none;
  line-height: 1.2;
}
@media screen and (max-width: 800px) {
  .c-notes__txt {
    padding: 15px 10px 20px;
  }
}
.c-notes__txt:first-of-type {
  display: block;
}
.c-notes__txt ul {
  padding-left: 1em;
}
.c-notes__txt ul li {
  padding-left: 1em;
  position: relative;
  line-height: 1.4;
}
.c-notes__txt ul li::before {
  content: "・";
  position: absolute;
  left: 0;
  top: 0;
}
.c-notes__txt ul.list {
  padding-left: 0;
}
.c-notes__txt ul.list li {
  margin-bottom: 1em;
}
.c-notes__txt dl dt {
  font-weight: 500;
  margin-bottom: 1em;
}
.c-notes__txt dl dd {
  padding-bottom: 1em;
}
.c-notes__txt dl dd p {
  margin-bottom: 1em;
}

.p-top-mv {
  position: relative;
  padding-bottom: 10px;
}
.p-top-mv__img {
  margin-bottom: 10px;
}
.p-top-mv .c-btn01 {
  margin: 5px auto 0;
}
.p-top-mv::after {
  content: "";
  width: 100%;
  height: 18px;
  background: url(../img/top/wave.png) repeat-x center top;
  position: absolute;
  left: 0;
  bottom: -17px;
  z-index: 2;
}
@media screen and (max-width: 800px) {
  .p-top-mv__simulation h2 {
    width: 90%;
    margin: 0 auto;
  }
}

.p-top-price {
  background: url(../img/top/bg01.jpg);
  padding: 60px 0 80px;
}
@media screen and (max-width: 800px) {
  .p-top-price {
    padding: 40px 0 60px;
  }
}
.p-top-price .c-box {
  background-color: #fc804c;
  padding-top: 20px;
}
@media screen and (max-width: 800px) {
  .p-top-price .c-box {
    padding-top: 10px;
    padding-bottom: 20px;
  }
}
.p-top-price__list {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
@media screen and (max-width: 800px) {
  .p-top-price__list {
    gap: 20px;
  }
}
.p-top-price__koelab {
  color: #fff;
  text-align: center;
  padding-top: 25px;
}
@media screen and (max-width: 800px) {
  .p-top-price__koelab {
    padding-top: 20px;
  }
}
.p-top-price__koelab h3 {
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 5px;
}
@media screen and (max-width: 800px) {
  .p-top-price__koelab h3 {
    font-size: 18px;
    line-height: 1.4;
  }
}
.p-top-price__koelab p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
}

.p-top-simulation {
  padding: 30px 0 90px;
  background: url(../img/top/bg02.jpg);
}
@media screen and (max-width: 800px) {
  .p-top-simulation {
    padding: 40px 0 60px;
  }
}
.p-top-simulation .c-box {
  background-color: #00b9b1;
}
.p-top-simulation .c-box:first-of-type {
  margin-bottom: 50px;
}
@media screen and (max-width: 800px) {
  .p-top-simulation .c-box:first-of-type {
    margin-bottom: 30px;
  }
}
.p-top-simulation__input .u-bg-white {
  padding: 10px 10px 45px;
}
@media screen and (max-width: 800px) {
  .p-top-simulation__input .u-bg-white {
    padding: 10px 5px 30px;
  }
}
.p-top-simulation__option .u-bg-white {
  padding: 40px 40px 60px;
}
@media screen and (max-width: 800px) {
  .p-top-simulation__option .u-bg-white {
    padding: 15px 15px 30px;
  }
}
.p-top-simulation__option .u-bg-white dl dt {
  font-weight: 500;
  padding-left: 1em;
  position: relative;
}
.p-top-simulation__option .u-bg-white dl dt::before {
  content: "■";
  position: absolute;
  left: 0;
  top: 0;
}
.p-top-simulation__option .u-bg-white dl dd {
  padding-left: 1em;
  font-weight: 500;
}

.p-top-other {
  padding: 30px 0 90px;
  background: url(../img/top/bg03.jpg);
}
@media screen and (max-width: 800px) {
  .p-top-other {
    padding: 40px 0 60px;
  }
}
.p-top-other .c-box {
  background-color: #ffce26;
  margin-top: 50px;
}
@media screen and (max-width: 800px) {
  .p-top-other .c-box {
    margin-top: 30px;
  }
}
.p-top-other .c-box .u-bg-white {
  padding: 40px 30px;
}
@media screen and (max-width: 800px) {
  .p-top-other .c-box .u-bg-white {
    padding: 20px 15px;
  }
}
.p-top-other .c-box .u-bg-white h3 {
  margin-bottom: 30px;
}
@media screen and (max-width: 800px) {
  .p-top-other .c-box .u-bg-white h3 {
    max-width: 80%;
    margin: 0 auto 20px;
  }
}
.p-top-other .c-box .u-bg-white .mb {
  margin-bottom: 1.8em;
}
@media screen and (max-width: 800px) {
  .p-top-other .c-box .u-bg-white .mb {
    margin-bottom: 1.5em;
  }
}

.p-top-notes {
  padding: 30px 0 90px;
  background: url(../img/top/bg04.jpg);
}
@media screen and (max-width: 800px) {
  .p-top-notes {
    padding: 40px 0 60px;
  }
}
.p-top-notes .c-box {
  background-color: #fe75b0;
}

.p-top-flow {
  padding: 30px 0 90px;
  background: url(../img/top/bg05.jpg);
}
@media screen and (max-width: 800px) {
  .p-top-flow {
    padding: 40px 0 60px;
  }
}
.p-top-flow .c-box {
  background-color: #00b9b1;
  padding-top: 70px;
}
@media screen and (max-width: 800px) {
  .p-top-flow .c-box {
    padding-top: 40px;
  }
}

.p-top-form {
  padding: 30px 0 90px;
  background: url(../img/top/bg06.jpg);
}
@media screen and (max-width: 800px) {
  .p-top-form {
    padding: 40px 0 60px;
  }
}
.p-top-form .c-box {
  background-color: #3f88e9;
}
.p-top-form .c-box:first-of-type {
  margin-bottom: 50px;
}
@media screen and (max-width: 800px) {
  .p-top-form .c-box:first-of-type {
    margin-bottom: 30px;
  }
}
.p-top-form .c-box .u-bg-white {
  padding: 60px 50px 70px;
}
@media screen and (max-width: 800px) {
  .p-top-form .c-box .u-bg-white {
    padding: 20px 10px 30px;
  }
}
.p-top-form__iso h3 {
  font-size: 44px;
  font-weight: 800;
  color: #3f88e9;
  letter-spacing: 0.1em;
  line-height: 1.2;
  margin-bottom: 20px;
}
@media screen and (max-width: 800px) {
  .p-top-form__iso h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
}
.p-top-form__iso.u-bg-blue {
  margin-top: 45px;
}
.p-top-form__iso.u-bg-blue .u-bg-white {
  padding: 60px 50px 70px;
}
@media screen and (max-width: 800px) {
  .p-top-form__iso.u-bg-blue .u-bg-white {
    padding: 20px 10px 30px;
  }
}
.p-top-form__caution {
  padding-top: 50px;
  line-height: 1.2;
  font-size: 18px;
}
@media screen and (max-width: 800px) {
  .p-top-form__caution {
    font-size: 14px;
    padding-top: 30px;
  }
}
.p-top-form__caution p:last-child {
  margin-top: 1.2em;
}

.p-thanks .c-btn02 {
  margin-top: 30px;
}
@media screen and (max-width: 800px) {
  .p-thanks .c-btn02 {
    margin-top: 20px;
  }
}

.p-tradelaw .u-bg-white {
  padding: 6px;
}
.p-tradelaw .u-bg-white table {
  font-size: 28px;
}
@media screen and (max-width: 800px) {
  .p-tradelaw .u-bg-white table {
    font-size: 16px;
  }
}
.p-tradelaw .u-bg-white table th,
.p-tradelaw .u-bg-white table td {
  font-weight: 500;
  border: 2px solid #fff;
  padding: 20px;
  vertical-align: middle;
  text-align: left;
}
@media screen and (max-width: 800px) {
  .p-tradelaw .u-bg-white table th,
  .p-tradelaw .u-bg-white table td {
    padding: 10px;
  }
}
.p-tradelaw .u-bg-white table th {
  background-color: #ffc239;
  width: 283px;
}
@media screen and (max-width: 800px) {
  .p-tradelaw .u-bg-white table th {
    width: 100%;
    display: block;
    border-bottom: none;
    padding: 5px 10px;
  }
}
.p-tradelaw .u-bg-white table td {
  background-color: #fff9eb;
}
@media screen and (max-width: 800px) {
  .p-tradelaw .u-bg-white table td {
    width: 100%;
    display: block;
    border-top: none;
  }
}

.p-kiyaku .u-bg-white {
  padding: 30px;
}
@media screen and (max-width: 800px) {
  .p-kiyaku .u-bg-white {
    padding: 10px;
  }
}
.p-kiyaku dl dt {
  font-size: 28px;
}
@media screen and (max-width: 800px) {
  .p-kiyaku dl dt {
    font-size: 18px;
  }
}
.p-kiyaku dl dd {
  margin-bottom: 1em;
}
.p-kiyaku dl dd ol {
  counter-reset: number 0;
}
.p-kiyaku dl dd ol li {
  position: relative;
  padding-left: 1.5em;
}
.p-kiyaku dl dd ol li::before {
  counter-increment: number 1;
  content: counter(number) ".";
  position: absolute;
  left: 0;
  top: 0;
}
.p-kiyaku dl dd ol li:not(:last-child) {
  margin-bottom: 1.5em;
}
.p-kiyaku dl dd a {
  text-decoration: underline;
}

.p-exp_fs .u-bg-orange .u-bg-white {
  padding: 15px;
  margin-bottom: 65px;
  line-height: 1.2;
}
@media screen and (max-width: 800px) {
  .p-exp_fs .u-bg-orange .u-bg-white {
    padding: 10px;
    padding-bottom: 20px;
  }
}
.p-exp_fs .c-list-flow {
  padding: 55px 15px 20px;
}
@media screen and (max-width: 800px) {
  .p-exp_fs .c-list-flow {
    padding: 30px 10px 20px;
  }
}
.p-exp_fs .c-list-flow dt {
  background-color: #ffc239;
}
.p-exp_fs .c-list-flow dt::before {
  border-color: #ffc239 transparent transparent transparent;
}
.p-exp_fs .c-list-flow dd {
  gap: 20px;
}
@media screen and (max-width: 1039px) {
  .p-exp_fs .c-list-flow dd {
    flex-wrap: wrap;
    gap: 10px;
  }
}
.p-exp_fs .c-list-flow dd .img {
  width: 560px;
  flex-shrink: 0;
}
@media screen and (max-width: 1039px) {
  .p-exp_fs .c-list-flow dd .img {
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .p-exp_fs .c-list-flow dd .txt {
    padding: 0 10px;
  }
}

.u-tac {
  text-align: center;
}

.u-orange {
  color: #fc804c;
}

.u-pink {
  color: #fe75b0;
}

.u-bg-white {
  background-color: #fff;
}

.u-bg-orange {
  background-color: #fc804c;
}

.u-bg-blue {
  background-color: #3f88e9;
}

.u-bg-orangeicn {
  background: url(../img/top/bg01.jpg);
}

.u-medium {
  font-weight: 500;
}

.u-bold {
  font-weight: bold;
}