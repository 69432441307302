@use "sass:math";
@use "../global" as *;

.c-list-needs {
  display: grid;
  gap: 30px 50px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 40px;
  @include mq {
    grid-template-columns: 1fr;
    gap: 20px;
    margin-bottom: 30px;
  }
  &__item {
    display: flex;
    align-items: center;
    gap: 20px;
    @include mq {
      gap: 15px;
      line-height: 1.4;
    }
    .img {
      width: 118px;
      @include mq {
        width: 60px;
      }
    }
  }
}

.c-list-fixed {
  position: fixed;
  z-index: 9999;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $white;
  display: none;
  @include mq {
    display: flex;
  }
  &__item {
    width: 50%;
    line-height: 1.2;
    .c-btn01 {
      max-width: 100%;
      padding: 5px 10px 3px;
      &__icn {
        width: 16px;
        margin-bottom: 3px;
      }
    }
  }
}

.c-list-flow {
  position: relative;
  background-color: $white;
  padding: 50px 40px 40px;
  @include mq {
    padding: 30px 15px 15px;
  }
  &:not(:last-of-type) {
    margin-bottom: 65px;
    @include mq {
      margin-bottom: 40px;
    }
  }
  dt {
    background-color: $l_green;
    width: 90%;
    max-width: 485px;
    text-align: center;
    color: $white;
    font-weight: 500;
    font-size: 35px;
    border-radius: 10px;
    border: 2px solid $white;
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    @include mq {
      max-width: 300px;
      font-size: 18px;
    }
    &::before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 20px 10px 0 10px;
      border-color: $l_green transparent transparent transparent;
      position: absolute;
      left: 50%;
      bottom: -18px;
      margin-left: -10px;
      @include mq {
        border-width: 10px 5px 0 5px;
        bottom: -9px;
        margin-left: -5px;
      }
    }
  }
  dd {
    display: flex;
    gap: 40px;
    line-height: 1.2;
    @include mq {
      gap: 10px;
    }
    .icn {
      width: 80px;
      text-align: center;
      flex-shrink: 0;
      @include mq {
        width: 40px;
      }
    }
    .txt {
      margin-top: 0.5em;
      p {
        &:not(:first-of-type) {
          margin-top: 1.5em;
          @include mq {
            margin-top: 0;
          }
        }
      }
      a {
        color: $blue;
      }
    }
  }
}
