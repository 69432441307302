$base: 1440;
$baseSp: 375;

// color
$white: #fff;
$black: #1a1a1a;
$txtColor: #010101;
$orange: #fc804c;
$green: #00b9b1;
$l_green: #0eccc3;
$d_green: #46cc64;
$yellow: #ffce26;
$l_yellow: #fee300;
$d_yellow: #ffc239;
$pink: #fe75b0;
$blue: #3f88e9;
$red: #d00e31;
