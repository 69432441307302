@use "sass:math";
@use "../global" as *;

.c-btn01 {
  display: block;
  max-width: 302px;
  padding: 5px 15px;
  text-align: center;
  color: $white;
  @include mq(tb) {
    max-width: 200px;
  }
  &__icn {
    display: block;
    @include mq(tb) {
      width: 20px;
      margin: 0 auto;
    }
  }
  &__txt {
    font-size: 16px;
    display: block;
    @include mq(tb) {
      font-size: 10px;
    }
  }
  &--green {
    background-color: $d_green;
  }
  &--blue {
    background-color: $blue;
  }
  &--red {
    background-color: $red;
  }
}

.c-btn02 {
  display: block;
  font-weight: 800;
  background-color: $white;
  color: $orange;
  max-width: 320px;
  height: 50px;
  line-height: 50px;
  border-radius: 10px;
  margin: 0 auto;
  text-align: center;
  @include mq {
    border-radius: 5px;
    max-width: 200px;
  }
}

.c-btn-cv {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
  width: 100%;
  max-width: 720px;
  border-radius: 16px;
  border: 2px solid $white;
  background-color: $orange;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  font-size: 44px;
  color: $white;
  font-weight: 800;
  line-height: 1;
  transition: opacity 0.3s;
  @include mq {
    border-radius: 10px;
    width: 90%;
    max-width: 300px;
    font-size: 16px;
  }
  &:hover {
    opacity: 0.7;
  }
  &.btn-send {
    img {
      width: 48px;
      @include mq {
        width: 24px;
      }
    }
  }
}
