@use "sass:math";
@use "../global" as *;

.c-cv {
  padding: 45px 0;
  background-color: $l_yellow;
  @include mq {
    padding: 30px 0;
  }
  h2 {
    margin-bottom: 10px;
    @include mq {
      width: 80%;
      margin: 0 auto 10px;
    }
  }
}
