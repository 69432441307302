@use "sass:math";
@use "../global" as *;

.p-exp_fs {
  .u-bg-orange {
    .u-bg-white {
      padding: 15px;
      margin-bottom: 65px;
      line-height: 1.2;
      @include mq {
        padding: 10px;
        padding-bottom: 20px;
      }
    }
  }
  .c-list-flow {
    padding: 55px 15px 20px;
    @include mq {
      padding: 30px 10px 20px;
    }
    dt {
      background-color: $d_yellow;
      &::before {
        border-color: $d_yellow transparent transparent transparent;
      }
    }
    dd {
      gap: 20px;
      @include mq(tb) {
        flex-wrap: wrap;
        gap: 10px;
      }
      .img {
        width: 560px;
        flex-shrink: 0;
        @include mq(tb) {
          width: 100%;
        }
      }
      .txt {
        @include mq {
          padding: 0 10px;
        }
      }
    }
  }
}
