@use "sass:math";
@use "../global" as *;

.p-top-mv {
  position: relative;
  padding-bottom: 10px;
  &__img {
    margin-bottom: 10px;
  }
  .c-btn01 {
    margin: 5px auto 0;
  }
  &::after {
    content: "";
    width: 100%;
    height: 18px;
    background: url(../img/top/wave.png) repeat-x center top;
    position: absolute;
    left: 0;
    bottom: -17px;
    z-index: 2;
  }
  &__simulation {
    h2 {
      @include mq {
        width: 90%;
        margin: 0 auto;
      }
    }
  }
}

.p-top-price {
  background: url(../img/top/bg01.jpg);
  padding: 60px 0 80px;
  @include mq {
    padding: 40px 0 60px;
  }
  .c-box {
    background-color: $orange;
    padding-top: 20px;
    @include mq {
      padding-top: 10px;
      padding-bottom: 20px;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 40px;
    @include mq {
      gap: 20px;
    }
  }
  &__koelab {
    color: $white;
    text-align: center;
    padding-top: 25px;
    @include mq {
      padding-top: 20px;
    }
    h3 {
      font-size: 23px;
      font-weight: 500;
      margin-bottom: 5px;
      @include mq {
        font-size: 18px;
        line-height: 1.4;
      }
    }
    p {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 15px;
    }
  }
}

.p-top-simulation {
  padding: 30px 0 90px;
  background: url(../img/top/bg02.jpg);
  @include mq {
    padding: 40px 0 60px;
  }
  .c-box {
    background-color: $green;
    &:first-of-type {
      margin-bottom: 50px;
      @include mq {
        margin-bottom: 30px;
      }
    }
  }
  &__input {
    .u-bg-white {
      padding: 10px 10px 45px;
      @include mq {
        padding: 10px 5px 30px;
      }
    }
  }
  &__option {
    .u-bg-white {
      padding: 40px 40px 60px;
      @include mq {
        padding: 15px 15px 30px;
      }
      dl {
        dt {
          font-weight: 500;
          padding-left: 1em;
          position: relative;
          &::before {
            content: "■";
            position: absolute;
            left: 0;
            top: 0;
          }
        }
        dd {
          padding-left: 1em;
          font-weight: 500;
        }
      }
    }
  }
}

.p-top-other {
  padding: 30px 0 90px;
  background: url(../img/top/bg03.jpg);
  @include mq {
    padding: 40px 0 60px;
  }
  .c-box {
    background-color: $yellow;
    margin-top: 50px;
    @include mq {
      margin-top: 30px;
    }
    .u-bg-white {
      padding: 40px 30px;
      @include mq {
        padding: 20px 15px;
      }
      h3 {
        margin-bottom: 30px;
        @include mq {
          max-width: 80%;
          margin: 0 auto 20px;
        }
      }
      .mb {
        margin-bottom: 1.8em;
        @include mq {
          margin-bottom: 1.5em;
        }
      }
    }
  }
}

.p-top-notes {
  padding: 30px 0 90px;
  background: url(../img/top/bg04.jpg);
  @include mq {
    padding: 40px 0 60px;
  }
  .c-box {
    background-color: $pink;
  }
}

.p-top-flow {
  padding: 30px 0 90px;
  @include mq {
    padding: 40px 0 60px;
  }
  background: url(../img/top/bg05.jpg);
  .c-box {
    background-color: $green;
    padding-top: 70px;
    @include mq {
      padding-top: 40px;
    }
  }
}

.p-top-form {
  padding: 30px 0 90px;
  background: url(../img/top/bg06.jpg);
  @include mq {
    padding: 40px 0 60px;
  }
  .c-box {
    background-color: $blue;
    &:first-of-type {
      margin-bottom: 50px;
      @include mq {
        margin-bottom: 30px;
      }
    }
    .u-bg-white {
      padding: 60px 50px 70px;
      @include mq {
        padding: 20px 10px 30px;
      }
    }
  }
  &__iso {
    h3 {
      font-size: 44px;
      font-weight: 800;
      color: $blue;
      letter-spacing: 0.1em;
      line-height: 1.2;
      margin-bottom: 20px;
      @include mq {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }
    &.u-bg-blue {
      margin-top: 45px;
      .u-bg-white {
        padding: 60px 50px 70px;
        @include mq {
          padding: 20px 10px 30px;
        }
      }
    }
  }
  &__caution {
    padding-top: 50px;
    line-height: 1.2;
    font-size: 18px;
    @include mq {
      font-size: 14px;
      padding-top: 30px;
    }
    p {
      &:last-child {
        margin-top: 1.2em;
      }
    }
  }
}
